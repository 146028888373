import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { measureInitialPageLoadAsyncResourcesTiming } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import { PERFORMANCE_KEYS } from '@atlassian/jira-forge-ui-analytics/src/constants';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export type Bridge = {
	submit: (opts: { data: ForgeCustomFieldValue }) => Promise<void>;
};

const getModule = () =>
	measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_INLINE_EDIT_BUNDLE_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_INLINE_EDIT_BUNDLE_END,
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-custom-field-inline-edit" */ '@atlassian/jira-forge-ui/src/ui/components/custom-field/edit'
			),
	);

const getForgeExtensionModule = () =>
	measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_INLINE_EDIT_BUNDLE_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_INLINE_EDIT_BUNDLE_END,
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-custom-field-inline-edit-extension" */ '@atlassian/jira-forge-ui/src/common/ui/extension/components/custom-field/edit'
			),
	);

export const LazyCustomFieldInlineEditRenderer = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.CustomFieldInlineEdit), { ssr: false }),
);

export const LazyCustomFieldInlineEditExtension = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getForgeExtensionModule().then((module) => module.CustomFieldInlineEdit), {
		ssr: false,
	}),
);
