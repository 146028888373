/**
 * This file was automatically generated by @atlaskit/adf-schema-generator
 * DO NOT MODIFY IT BY HAND. Instead, modify the source files in "packages/adf-schema/src/next-schema" ,
 * and run "yarn workspace @atlaskit/adf-schema build:schema:all" to regenerate this file.
 */

import { createPMNodeSpecFactory } from '../../schema/createPMSpecFactory';
export const blockCard = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    url: {
      default: null
    },
    datasource: {
      default: null
    },
    width: {
      default: null
    },
    layout: {
      default: null
    },
    data: {
      default: null
    }
  },
  selectable: true,
  draggable: true
});
export const blockquote = createPMNodeSpecFactory({
  content: '(paragraph | orderedList | bulletList | unsupportedBlock | codeBlock | mediaSingle | mediaGroup)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: true,
  defining: true
});
export const blockquoteLegacy = createPMNodeSpecFactory({
  content: '(paragraph | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: true,
  defining: true
});
export const blockquoteWithoutNestedCodeblockOrMedia = createPMNodeSpecFactory({
  content: '(paragraph | orderedList | bulletList | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: true,
  defining: true
});
export const bodiedExtension = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | embedCard | extension | unsupportedBlock)+',
  marks: 'dataConsumer fragment unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true,
  defining: true,
  isolating: true
});
export const bodiedExtensionWithMarks = createPMNodeSpecFactory({
  marks: 'dataConsumer fragment unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true,
  defining: true,
  isolating: true
});
export const bulletList = createPMNodeSpecFactory({
  content: 'listItem+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  selectable: false
});
export const caption = createPMNodeSpecFactory({
  content: '(hardBreak | mention | emoji | date | placeholder | inlineCard | status | text | unsupportedInline)*',
  marks: '_',
  selectable: false,
  isolating: true
});
export const codeBlock = createPMNodeSpecFactory({
  content: '(text | unsupportedInline)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    language: {
      default: null
    },
    uniqueId: {
      default: null
    }
  },
  code: true,
  defining: true
});
export const codeBlockWithNoMarks = createPMNodeSpecFactory({
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    language: {
      default: null
    },
    uniqueId: {
      default: null
    }
  },
  code: true,
  defining: true
});
export const confluenceJiraIssue = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  atom: true,
  attrs: {
    issueKey: {
      default: ''
    },
    macroId: {
      default: null
    },
    schemaVersion: {
      default: null
    },
    server: {
      default: null
    },
    serverId: {
      default: null
    }
  }
});
export const confluenceUnsupportedBlock = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    cxhtml: {
      default: null
    }
  }
});
export const confluenceUnsupportedInline = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  atom: true,
  attrs: {
    cxhtml: {
      default: null
    }
  }
});
export const date = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    timestamp: {
      default: ''
    }
  },
  selectable: true
});
export const dateStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    timestamp: {
      default: ''
    }
  },
  selectable: true
});
export const decisionItem = createPMNodeSpecFactory({
  content: 'inline*',
  marks: '_',
  attrs: {
    localId: {
      default: ''
    },
    state: {
      default: 'DECIDED'
    }
  },
  defining: true
});
export const decisionList = createPMNodeSpecFactory({
  content: '(decisionItem | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    localId: {
      default: ''
    }
  },
  selectable: false,
  defining: true
});
export const doc = createPMNodeSpecFactory({
  content: '(block | layoutSection | blockRootOnly)+',
  marks: 'unsupportedMark unsupportedNodeAttribute alignment indentation dataConsumer fragment breakout'
});
export const embedCard = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    url: {
      default: ''
    },
    layout: {
      default: 'center'
    },
    width: {
      default: 100
    },
    originalHeight: {
      default: null
    },
    originalWidth: {
      default: null
    }
  },
  selectable: true
});
export const emoji = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    shortName: {
      default: ''
    },
    id: {
      default: ''
    },
    text: {
      default: ''
    }
  },
  selectable: true
});
export const emojiStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    shortName: {
      default: ''
    },
    id: {
      default: ''
    },
    text: {
      default: ''
    }
  },
  selectable: true
});
export const expand = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | embedCard | extension | unsupportedBlock | nestedExpand)+',
  marks: 'unsupportedMark unsupportedNodeAttribute fragment dataConsumer',
  group: 'block',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export const expandWithNoMark = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export const expandWithoutNestedExpand = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | blockCard | embedCard | extension | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute fragment dataConsumer',
  group: 'block',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export const extension = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export const extensionWithMarks = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export const extensionFrameStage0 = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaGroup | mediaSingle | decisionList | taskList | table | extension | bodiedExtension | unsupportedBlock | blockCard | embedCard)+',
  marks: 'dataConsumer fragment unsupportedMark unsupportedNodeAttribute',
  attrs: {},
  selectable: false,
  definingAsContext: false,
  definingForContent: true,
  isolating: true
});
export const hardBreak = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  selectable: false
});
export const heading = createPMNodeSpecFactory({
  content: 'inline*',
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export const headingWithAlignment = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export const headingWithIndentation = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export const headingWithNoMarks = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    level: {
      default: 1
    },
    localId: {
      default: null
    }
  },
  selectable: false,
  defining: true
});
export const image = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    src: {
      default: ''
    },
    alt: {
      default: ''
    },
    title: {
      default: null
    }
  },
  draggable: true
});
export const inlineCard = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    url: {
      default: null
    },
    data: {
      default: null
    }
  },
  selectable: true,
  draggable: true
});
export const inlineCardStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    url: {
      default: null
    },
    data: {
      default: null
    }
  },
  selectable: true,
  draggable: true
});
export const inlineExtension = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export const inlineExtensionWithMarks = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    localId: {
      default: null
    }
  },
  selectable: true
});
export const layoutColumn = createPMNodeSpecFactory({
  content: '(block | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute alignment indentation dataConsumer fragment',
  attrs: {
    width: {
      default: undefined
    }
  },
  selectable: false,
  isolating: true
});
export const layoutSection = createPMNodeSpecFactory({
  content: '(layoutColumn | unsupportedBlock){1,3} unsupportedBlock* | unsupportedBlock+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  isolating: true
});
export const layoutSectionFull = createPMNodeSpecFactory({
  content: '(layoutColumn | unsupportedBlock){2,3}',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  isolating: true
});
export const layoutSectionWithSingleColumnStage0 = createPMNodeSpecFactory({
  content: '(layoutColumn | unsupportedBlock){1,3}',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  isolating: true
});
export const listItem = createPMNodeSpecFactory({
  content: '(paragraph | mediaSingle | codeBlock | unsupportedBlock) (paragraph | bulletList | orderedList | taskList | mediaSingle | codeBlock | unsupportedBlock)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  defining: true
});
export const listItemLegacy = createPMNodeSpecFactory({
  content: '(paragraph | mediaSingle | codeBlock | unsupportedBlock) (paragraph | bulletList | orderedList | mediaSingle | codeBlock | unsupportedBlock)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  defining: true
});
export const listItemWithNestedDecisionStage0 = createPMNodeSpecFactory({
  content: '(paragraph | mediaSingle | codeBlock | unsupportedBlock | decisionList) (paragraph | bulletList | orderedList | taskList | mediaSingle | codeBlock | unsupportedBlock | decisionList)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  defining: true
});
export const media = createPMNodeSpecFactory({
  attrs: {
    type: {
      default: 'file'
    },
    id: {
      default: ''
    },
    alt: {
      default: ''
    },
    collection: {
      default: ''
    },
    height: {
      default: null
    },
    occurrenceKey: {
      default: null
    },
    width: {
      default: null
    },
    __contextId: {
      default: null
    },
    __displayType: {
      default: null
    },
    __external: {
      default: false
    },
    __fileMimeType: {
      default: null
    },
    __fileName: {
      default: null
    },
    __fileSize: {
      default: null
    },
    __mediaTraceId: {
      default: null
    },
    url: {
      default: null
    }
  },
  selectable: true
});
export const mediaGroup = createPMNodeSpecFactory({
  content: '(media | unsupportedBlock)+',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {},
  selectable: false
});
export const mediaInline = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    type: {
      default: 'file'
    },
    url: {
      default: null
    },
    id: {
      default: ''
    },
    alt: {
      default: ''
    },
    collection: {
      default: ''
    },
    occurrenceKey: {
      default: null
    },
    width: {
      default: null
    },
    height: {
      default: null
    },
    __fileName: {
      default: null
    },
    __fileSize: {
      default: null
    },
    __fileMimeType: {
      default: null
    },
    __displayType: {
      default: null
    },
    __contextId: {
      default: null
    },
    __mediaTraceId: {
      default: null
    },
    __external: {
      default: false
    }
  },
  selectable: true
});
export const mediaSingle = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    layout: {
      default: 'center'
    },
    width: {
      default: null
    }
  },
  selectable: true,
  content: 'media|unsupportedBlock+|media unsupportedBlock+',
  marks: 'annotation border link unsupportedMark unsupportedNodeAttribute'
});
export const mediaSingleCaption = createPMNodeSpecFactory({
  content: 'media|unsupportedBlock+|media (caption|unsupportedBlock) unsupportedBlock*',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  atom: false,
  attrs: {
    layout: {
      default: 'center'
    },
    width: {
      default: null
    }
  },
  selectable: true
});
export const mediaSingleFull = createPMNodeSpecFactory({
  content: 'media|unsupportedBlock+|media (caption|unsupportedBlock) unsupportedBlock*',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  atom: false,
  attrs: {
    width: {
      default: null
    },
    layout: {
      default: 'center'
    },
    widthType: {
      default: null
    }
  },
  selectable: true
});
export const mediaSingleWidthType = createPMNodeSpecFactory({
  content: 'media|unsupportedBlock+|media unsupportedBlock+',
  marks: 'link annotation border unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  atom: true,
  attrs: {
    width: {
      default: null
    },
    layout: {
      default: 'center'
    },
    widthType: {
      default: null
    }
  },
  selectable: true
});
export const mention = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    id: {
      default: ''
    },
    localId: {
      default: null
    },
    text: {
      default: ''
    },
    accessLevel: {
      default: ''
    },
    userType: {
      default: null
    }
  },
  selectable: true
});
export const mentionStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    id: {
      default: ''
    },
    localId: {
      default: null
    },
    text: {
      default: ''
    },
    accessLevel: {
      default: ''
    },
    userType: {
      default: null
    }
  },
  selectable: true
});
export const multiBodiedExtensionStage0 = createPMNodeSpecFactory({
  content: 'extensionFrame+',
  marks: 'unsupportedNodeAttribute unsupportedMark',
  group: 'blockRootOnly',
  attrs: {
    extensionKey: {
      default: ''
    },
    extensionType: {
      default: ''
    },
    parameters: {
      default: null
    },
    text: {
      default: null
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: null
    }
  },
  selectable: true,
  definingAsContext: true
});
export const nestedExpand = createPMNodeSpecFactory({
  content: '(paragraph | heading | mediaSingle | mediaGroup | codeBlock | bulletList | orderedList | taskList | decisionList | rule | panel | blockquote | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export const nestedExpandWithNoMarks = createPMNodeSpecFactory({
  attrs: {
    title: {
      default: ''
    },
    __expanded: {
      default: true
    }
  },
  selectable: true,
  isolating: true
});
export const orderedList = createPMNodeSpecFactory({
  content: 'listItem+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    order: {
      default: 1
    }
  },
  selectable: false
});
export const panel = createPMNodeSpecFactory({
  content: '(paragraph | heading | bulletList | orderedList | blockCard | mediaGroup | mediaSingle | codeBlock | taskList | rule | decisionList | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    panelType: {
      default: 'info'
    },
    panelIcon: {
      default: null
    },
    panelIconId: {
      default: null
    },
    panelIconText: {
      default: null
    },
    panelColor: {
      default: null
    }
  },
  selectable: true
});
export const panelLegacy = createPMNodeSpecFactory({
  content: '(paragraph | heading | bulletList | orderedList | blockCard | unsupportedBlock)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    panelType: {
      default: 'info'
    },
    panelIcon: {
      default: null
    },
    panelIconId: {
      default: null
    },
    panelIconText: {
      default: null
    },
    panelColor: {
      default: null
    }
  },
  selectable: true
});
export const panelWithNestedBlockquoteStage0 = createPMNodeSpecFactory({
  content: '(paragraph | heading | bulletList | orderedList | blockCard | mediaGroup | mediaSingle | codeBlock | taskList | rule | decisionList | unsupportedBlock | blockquote)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    panelType: {
      default: 'info'
    },
    panelIcon: {
      default: null
    },
    panelIconId: {
      default: null
    },
    panelIconText: {
      default: null
    },
    panelColor: {
      default: null
    }
  },
  selectable: true
});
export const paragraph = createPMNodeSpecFactory({
  content: 'inline*',
  marks: 'link em strong strike subsup underline textColor annotation backgroundColor typeAheadQuery confluenceInlineComment unsupportedNodeAttribute unsupportedMark code dataConsumer fragment border',
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export const paragraphWithAlignment = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export const paragraphWithIndentation = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export const paragraphWithNoMarks = createPMNodeSpecFactory({
  group: 'block',
  attrs: {
    localId: {
      default: null
    }
  },
  selectable: false
});
export const placeholder = createPMNodeSpecFactory({
  marks: '',
  group: 'inline',
  inline: true,
  attrs: {
    text: {
      default: ''
    }
  },
  selectable: false
});
export const rule = createPMNodeSpecFactory({
  group: 'block'
});
export const status = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    text: {
      default: ''
    },
    color: {
      default: ''
    },
    localId: {
      default: ''
    },
    style: {
      default: ''
    }
  },
  selectable: true
});
export const statusStage0 = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    text: {
      default: ''
    },
    color: {
      default: ''
    },
    localId: {
      default: ''
    },
    style: {
      default: ''
    }
  },
  selectable: true
});
export const table = createPMNodeSpecFactory({
  content: 'tableRow+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    displayMode: {
      default: null
    },
    isNumberColumnEnabled: {
      default: false
    },
    layout: {
      default: 'default'
    },
    localId: {
      default: ''
    },
    width: {
      default: null
    },
    __autoSize: {
      default: false
    }
  },
  selectable: true,
  isolating: true,
  tableRole: 'table'
});
export const tableCell = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaSingle | mediaGroup | decisionList | taskList | blockCard | embedCard | extension | nestedExpand | unsupportedBlock)+',
  marks: 'alignment unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  attrs: {
    colspan: {
      default: 1
    },
    rowspan: {
      default: 1
    },
    colwidth: {
      default: null
    },
    background: {
      default: null
    }
  },
  selectable: false,
  isolating: true,
  tableRole: 'cell'
});
export const tableHeader = createPMNodeSpecFactory({
  content: '(paragraph | panel | blockquote | orderedList | bulletList | rule | heading | codeBlock | mediaSingle | mediaGroup | decisionList | taskList | blockCard | embedCard | extension | nestedExpand)+',
  marks: 'alignment unsupportedMark unsupportedNodeAttribute dataConsumer fragment',
  attrs: {
    colspan: {
      default: 1
    },
    rowspan: {
      default: 1
    },
    colwidth: {
      default: null
    },
    background: {
      default: null
    }
  },
  selectable: false,
  isolating: true,
  tableRole: 'header_cell'
});
export const tableRow = createPMNodeSpecFactory({
  content: '(tableCell | tableHeader)+',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  selectable: false,
  tableRole: 'row'
});
export const taskItem = createPMNodeSpecFactory({
  content: 'inline*',
  marks: '_',
  attrs: {
    localId: {
      default: ''
    },
    state: {
      default: 'TODO'
    }
  },
  selectable: false,
  defining: true
});
export const taskList = createPMNodeSpecFactory({
  content: '(taskItem | unsupportedBlock)+ (taskItem | taskList | unsupportedBlock)*',
  marks: 'unsupportedMark unsupportedNodeAttribute',
  group: 'block',
  attrs: {
    localId: {
      default: ''
    }
  },
  selectable: false,
  defining: true
});
export const text = createPMNodeSpecFactory({
  group: 'inline'
});
export const textCodeInline = createPMNodeSpecFactory({
  group: 'inline'
});
export const textFormatted = createPMNodeSpecFactory({
  group: 'inline'
});
export const unsupportedBlock = createPMNodeSpecFactory({
  group: 'block',
  atom: true,
  attrs: {
    originalValue: {
      default: {}
    }
  },
  selectable: true
});
export const unsupportedInline = createPMNodeSpecFactory({
  group: 'inline',
  inline: true,
  attrs: {
    originalValue: {
      default: {}
    }
  },
  selectable: true
});